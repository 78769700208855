import React, { Component } from 'react';
import {
  change, Field, reduxForm, reset
} from 'redux-form';
import { CDN_BANNER_IMAGE_URL } from '../../common/config';
import withNotify from '../../common/withNotify';
import {
  maxLength,
  renderField,
  required,
  allowNumberRegEx,
  urlRegEx,
} from '../../helpers/form-validations';
import withLoader from '../../common/withLoader';
import API from '../../utils/utils';

class AddEditNewHomeBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: {},
      imageView: '',
      bannerType: 'home_banner', // Default selected option
      bannerSequence: '',
      altText: '',
      link: '',
      fileSizeMessage: 'File Size (1920 * 540)', // Default file size message
      fileSizeRequirements: {
        home_banner: { width: 1920, height: 540 },
        full_banner: { width: 1920, height: 1080 },
        sub_banner: { width: 640, height: 325 },
        special_banner: { width: 1026, height: 302 },
      },
      selectedFile: null,
      validationError: ''
      // sliderImage: '',
    };
  }

  _resetForm = () => {
    const { dispatch, data } = this.props;
    if (Object.keys(data).length <= 0) {
      dispatch(reset('addEditNewHomeBanner'));
      this.setState({
        imageView: '', bannerType: 'home_banner', bannerSequence: '', altText: '', link: ''
      });
    }
    document.getElementById('modalNewAddEditSliderImage').click();
  };

  _onCancelImage = () => {
    this._resetForm();
  };

  _onSelectImage = async (imageData) => {
    const { notify } = this.props;
    const { fileSizeRequirements, bannerType } = this.state;
    if (imageData) {
      const file = imageData.files[0];
      const imageSize = file.size;
      // Validate file size
      if (imageSize > 1024000) {
        notify({
          type: 'warning',
          message: 'Image size should be less than 1MB.',
        });
        return;
      }
      // Validate file type
      const mimeType = file.type.split('/')[1];
      if (!['png', 'jpeg', 'webp'].includes(mimeType)) {
        notify({
          type: 'warning',
          message: 'Only jpeg/png/webp images are supported.',
        });
        return;
      }
      // Validate dimensions based on banner type
      const selectedBannerDimensions = fileSizeRequirements[bannerType];
      const image = new Image();
      image.onload = async () => {
        if (
          selectedBannerDimensions.width
          && selectedBannerDimensions.height
          && (image.width !== selectedBannerDimensions.width
            || image.height !== selectedBannerDimensions.height)
        ) {
          notify({
            type: 'warning',
            message: `Invalid dimensions. Required:
         ${selectedBannerDimensions.width}x${selectedBannerDimensions.height}.`,
          });
          return;
        }

        // Upload image if validation passes
        const uploadFormData = new FormData();
        uploadFormData.append('file', file);
        const { setLoader } = this.props;
        setLoader(true);
        const data = { imageFile: file };
        await API.uploadBannerImage(data).then((responseJson) => {
          setLoader(false);
          const { picture, status } = responseJson;
          if (status) {
            this.setState({ imageView: picture });
          }
        });
      };
      image.src = URL.createObjectURL(file);
    }
  };

  _onDeleteImage = () => {
    this.setState({ imageView: '' });
  };

  _onAddImage = async (formData) => {
    const {
      imageView,
    } = this.state;
    const { addEditSliderImage, notify } = this.props;
    if (imageView !== '') {
      const data = {
        image_url: imageView,
        alt_text: formData.alt_text,
        link: formData.link,
        banner_sequence: formData.sequence,
        banner_type: this.state.bannerType,
        title_en: formData.title_en,
        title_de: formData.title_de,
        title_du: formData.title_du,
        short_title_en: formData.short_title_en,
        short_title_de: formData.short_title_de,
        short_title_du: formData.short_title_du,
      };
      addEditSliderImage(data);
      this._resetForm();
    } else {
      notify({ type: 'error', message: 'Please Select Image' });
    }
  };

  // Handle banner type change
  handleBannerChange = (event) => {
    const selectedType = event.target.value;

    // Define file size messages for different banner types
    const fileSizeMessages = {
      home_banner: 'File Size (1920 * 540)',
      full_banner: 'File Size (1920 * 1080)',
      sub_banner: 'File Size (640 * 325)',
      special_banner: 'File Size (1026 * 302)',
    };

    this.setState({
      bannerType: selectedType,
      fileSizeMessage: fileSizeMessages[selectedType],
      validationError: '' // Clear validation error on type change
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = nextProps;
    const { dispatch } = this.props;
    if (
      JSON.stringify(data) !== JSON.stringify(this.props.data)
      && Object.keys(data).length > 0
    ) {
      this.setState({
        imageView: data.image_url,
        bannerType: data.banner_type,
      });
      dispatch(change('addEditNewHomeBanner', 'link', data.link));
      dispatch(change('addEditNewHomeBanner', 'alt_text', data.alt_text));
      dispatch(change('addEditNewHomeBanner', 'sequence', data.banner_sequence));
      dispatch(change('addEditNewHomeBanner', 'title_en', data.title_en));
      dispatch(change('addEditNewHomeBanner', 'title_de', data.title_de));
      dispatch(change('addEditNewHomeBanner', 'title_du', data.title_du));
      dispatch(
        change('addEditNewHomeBanner', 'short_title_en', data.short_title_en),
      );
      dispatch(
        change('addEditNewHomeBanner', 'short_title_de', data.short_title_de),
      );
      dispatch(
        change('addEditNewHomeBanner', 'short_title_du', data.short_title_du),
      );
    }
  }

  render() {
    const { handleSubmit, data } = this.props;
    const { imageView }
      = this.state;
    return (
      <div
        className="modal fade"
        id="modalNewAddEditSliderImage"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="formModal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="formModal">
                {`${Object.keys(data).length > 0 ? 'Edit' : 'Add'} Image`}
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => this._resetForm()}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              name="addEditNewHomeBanner"
              onSubmit={handleSubmit(this._onAddImage)}
            >
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label
                        htmlFor="image"
                        className="col-sm-3 col-form-label"
                      >
                        Banner Type
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          onChange={this.handleBannerChange}
                          value={this.state.bannerType}
                        >
                          <option value="home_banner">Home Banner</option>
                          <option value="full_banner" disabled={data.banner_type === 'home_banner'}>Full Banner</option>
                          <option value="sub_banner" disabled={data.banner_type === 'home_banner'}>
                            Sub Banner
                          </option>
                          <option value="special_banner" disabled={data.banner_type === 'home_banner'}>
                            Special Banner
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group form-row">
                      <label
                        htmlFor="image"
                        className="col-sm-3 col-form-label"
                      >
                        Image
                      </label>
                      <div className="col-sm-9">
                        <label
                          htmlFor="cat_image"
                          className="col-form-label from-row"
                        >
                          <small style={{ color: 'red' }}>
                            {this.state.fileSizeMessage}
                          </small>
                        </label>
                        <div className="row ml-3">
                          <img
                            src={
                              imageView === ''
                                ? '/assets/images/no-image-icon.png'
                                : CDN_BANNER_IMAGE_URL + imageView
                              // : IMAGE_BASE_URL + imageView
                            }
                            onError={() => {
                              this.onerror = null;
                              this.src = '/assets/images/no-image-icon.png';
                            }}
                            style={{ width: 50 }}
                            className="col-3"
                            alt="image"
                          />
                          <div className="col">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm col-6 ml-3 mb-1"
                              onClick={() => document.getElementById('selectedFile').click()}
                            >
                              {imageView === ''
                                ? 'Upload Image'
                                : 'Change Image'}
                            </button>
                            <input
                              style={{ display: 'none' }}
                              type="file"
                              id="selectedFile"
                              onChange={(e) => this._onSelectImage(e.target)}
                            />
                            {imageView !== '' && (
                              <button
                                type="button"
                                className="btn btn-sm btn-danger col-6 ml-3"
                                onClick={() => this._onDeleteImage()}
                              >
                                Delete Image
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Banner Sequence
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="sequence"
                          component={renderField}
                          className="form-control"
                          placeholder="Add sequence (Number)"
                          validate={[required, allowNumberRegEx]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Title(en)
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="title_en"
                          component={renderField}
                          className="form-control"
                          placeholder="Title(en)"
                          validate={[maxLength(200)]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Title(de)
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="title_de"
                          component={renderField}
                          className="form-control"
                          placeholder="Title(de))"
                          validate={[maxLength(200)]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Title(du)
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="title_du"
                          component={renderField}
                          className="form-control"
                          placeholder="Title(du)"
                          validate={[maxLength(200)]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Alt Text
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="alt_text"
                          component={renderField}
                          className="form-control"
                          placeholder="Alt Text"
                          validate={[required, maxLength(200)]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Short Title(en)
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="short_title_en"
                          component={renderField}
                          className="form-control"
                          placeholder="Short Title(en)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Short Title(de)
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="short_title_de"
                          component={renderField}
                          className="form-control"
                          placeholder="Short Title(de)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="text" className="col-sm-3 col-form-label">
                        Short Title(du)
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="short_title_du"
                          component={renderField}
                          className="form-control"
                          placeholder="Short Title(du)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group form-row">
                      <label htmlFor="link" className="col-sm-3 col-form-label">
                        Link
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="link"
                          component={renderField}
                          className="form-control"
                          placeholder="Link"
                          validate={[required, urlRegEx]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer bg-whitesmoke br">
                <button type="submit" className="btn btn-primary">
                  {Object.keys(data).length > 0 ? 'Update' : 'Save'}
                </button>
                <button
                  type="button"
                  className="btn btn-dark"
                  onClick={() => this._resetForm()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const withForm = reduxForm({
  enableReinitialize: true,
  form: 'addEditNewHomeBanner',
});
export default withLoader(withForm(withNotify(AddEditNewHomeBanner)));
